<template>
  <div>
    <Toast position="top-center" />
  <t-dropdown>
    <div
      slot="trigger"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler,
        isShown,
      }"
    >
      <button
        id="user-menu"
        class="flex items-center pr-3 text-sm text-gray-700 transition duration-150 ease-in-out bg-gray-300 border-0 border-gray-200 rounded-full focus:outline-none focus:shadow-solid"
        :class="{ 'border-gray-300 bg-gray-500 text-white ': isShown }"
        aria-label="User menu"
        aria-haspopup="true"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
        v-if="me != null && me.profile != null && me.profile.first_name"
      >
     
        <t-tag
          variant="avatar"
          class="inline-flex items-center justify-center h-10 w-10 rounded-full bg-blue-200 overflow-hidden leading-none text-center"
          >{{me.profile.first_name.substring(0, 1)}}</t-tag
        >

        Bonjour, {{ me.profile.first_name }}!
      </button>
    </div>

    <div slot-scope="{ hide, blurHandler }">
      <router-link to="/dashboard">
        <button
          class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          role="menuitem"
          @blur="blurHandler"
        >
          Accueil
        </button>
      </router-link>
      <router-link to="/user/me">
        <button
          class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          role="menuitem"
          @blur="blurHandler"
        >
          Mon profil
        </button>
      </router-link>
      <router-link to="/matches">
        <button
          class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          role="menuitem"
          @blur="blurHandler"
        >
          Mes Matchs
        </button>
      </router-link>
      <router-link to="/invitations">
        <button
          class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          role="menuitem"
          @blur="blurHandler"
        >
          Mes interactions
        </button>
      </router-link>
      <router-link to="/chat-rooms">
        <button
          class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
          role="menuitem"
          @blur="blurHandler"
        >
          Mes conversations
        </button>
      </router-link>
      <button
        class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
        role="menuitem"
        @blur="blurHandler"
      >
        Paramètres
      </button>

      <button
        class="block w-full px-4 py-2 text-sm leading-5 text-red-500 transition duration-150 ease-in-out border-t hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
        @click="disconnect"
      >
        Déconnexion
      </button>
    </div>
  </t-dropdown>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Toast from "primevue/toast";
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
export default {
  name: "ProfileMenu",
  components: {Toast},
  computed: {
    ...mapGetters(["auth"]),
   
  },
  watch: {
  auth: {
    handler(newAuth, oldAuth) {
      
      if (newAuth !== null) {
        // Call a method or perform actions when auth is not null
        // Instead of assigning a new value, call a method to refresh auth or perform actions
        //this.refreshAuth(newAuth);
        this.refreshMe(newAuth)
        
        
      }
    },
    deep: true,
    immediate: true,
  },
},
  data() {
    return {
      me: {},
      userNavigation: userNavigation,
    };
  },
  methods: {
    disconnect() {
      this.$toast.add({
        severity: "success",
        closable: false,
        summary: "Vous êtes déconnecté",
        life: 3000,
      });
      this.$store.dispatch("signOutAction");
      
    },
    refreshMe(newAuth) {
    // You can perform additional logic if needed
    this.me = newAuth;
  },
  },
};
</script>

<style scoped></style>
