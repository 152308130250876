<template>
    <div class="mobile-menu">
      <!-- Content goes here -->
  
      <!-- Bottom Navigation Tabs -->
      <nav class="bottom-tabs">
        <router-link to="/dashboard" class="tab-item">
          
         <div> <font-awesome-icon
                icon="fa-solid fa-home"
                style="font-size: 1.3rem; margin-top: 14px"
              />
            </div>
          <div>Accueil</div>
        </router-link>
        <router-link to="/matches" class="tab-item">
          
          <div> <font-awesome-icon
                 icon="fa-solid fa-search"
                 style="font-size: 1.3rem; margin-top: 14px"
               />
             </div>
           <div>Matching</div>
         </router-link>

         <router-link to="/invitations" class="tab-item">
          
          <div> <font-awesome-icon
                 icon="fa-solid fa-comments"
                 style="font-size: 1.3rem; margin-top: 14px"
               />
             </div>
           <div>Interactions</div>
         </router-link>
  
        <router-link to="/user/me" class="tab-item">
            <div> <font-awesome-icon
                icon="fa-solid fa-gear"
                style="font-size: 1.3rem; margin-top: 14px"
              />
            </div>
          <div>Profile</div>
        </router-link>
  
        <!--<router-link to="/settings" class="tab-item">
            <div> <font-awesome-icon
                icon="fa-solid fa-cog"
                style=" font-size: 1.3rem; margin-top: 14px"
              />
            </div>
          <div>Paramètres</div>
        </router-link>-->
      </nav>
    </div>
  </template>
  <script>
  import { mapGetters } from "vuex";
  
  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    { name: "Sign out", href: "#" },
  ];
  export default {
    name: "MobileMenu",
    components: {},
  };
  </script>
  <style scoped>
  .mobile-menu {
    /* Add styles for your main content container */
  }
  
  .bottom-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.1);
  }
  
  .tab-item {
    text-align: center;
    padding: 10px;
    text-decoration: none;
    color: #111;
  }
  
  /* Add more styles as needed */
  </style>
  