<template>
  <div>
    <header class="sticky-header__header flex">
      <div class="container flex mx-auto justify-between">
        <div class="logo">
          <RouterLink to="/dashboard"><img src="@/assets/sakina.png" width="200" /></RouterLink>
        </div>
        <!--<nav>
            <ul class="flex menu">
                <li class="active">Mes Matchs</li>
                <li>Recherche</li>
                <li>Contact</li>
            </ul>
        </nav>-->
        <div class="action mt-5 mr-2">
          <a href="/login" v-if="!hasLoggedUser" class="btn">Se connecter</a>
         <div v-else>
          <profile-menu ></profile-menu>
          <mobile-menu v-if="this.$route.name != 'Survey' && this.$route.name != 'Meeting'" class="mobile-menu"></mobile-menu>
         </div>
          
        </div>
      </div>
      
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileMenu from "./ProfileMenu.vue";
import MobileMenu from "./MobileMenu.vue";
import { RouterLink } from "vue-router";
export default {
  name: "Header",
  props: {
    logo: {
      type: String,
      required: false,
    },
  },
  components: {
    ProfileMenu,
    MobileMenu,
    RouterLink
},
  computed: {
    ...mapGetters(["hasLoggedUser", "profil"]),
  },
};
</script>
<style scoped>
.sticky-header__header {
  /* Stick to the top */
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  z-index: 9;
}
img {
  max-width: 100%;
}
.logo {
  width: 200px;
}
.btn {
  background-color: #cc1db9;
  color: #fff;
  padding: 10px 50px;
  border-radius: 50px;
  border: none;
  font-size: 1.2rem;
  margin: 17px 0px;
}

.action {
  text-align: right;
  max-width: 1240px;
}

.menu {
  width: 600px;
  margin: 25px 0;
}
.menu li {
  list-style: none;
  margin: 0px 20px;
  text-transform: uppercase;
  font-weight: 700;
}
li.active {
  border-bottom: 4px solid #cc1db9;
}
</style>
