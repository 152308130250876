<template>
<div id="default__layout">
<Header/>
    <slot />
<Footer/>
</div>

</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  name: "AppLayoutDefault",
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>

</style>
